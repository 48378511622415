// Customizable Area Start
import React from "react";
import Chart from "react-apexcharts";
import {
    Button,

} from "@material-ui/core";
import Loader from "./Loader";

export const WeightGraph = ({
    greeting,
    description,
    setStepFunc,
    questions,
    target_month,
    target_weight,
    weight_loss_data,
    weight_loss_month,
    weight_unit
}: any) => {
    if (!questions[0]) {
        return <>Invalid Question</>;
    }


    const goNext = () => {
        setStepFunc(1)

    }
    const chartoptions = {
        chart: {
            // width: '100%',
            // height: '100%',
            offsetX: 20,
            offsetY: 20,
            id: "chart",
            foreColor: "#5A55A4",
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        xaxis: {
            offsetX: -1,
            offsetY: 0,
            categories: weight_loss_month
        },

        tooltip: { enabled: false },
        theme: {
            palette: "#5A55A4",
            monochrome: {
                enabled: true,
                color: "#5A55A4",
                shadeIntensity: 1
            }
        },
        stroke: {
            show: true,
            curve: 'smooth' as unknown as undefined
        },
        dataLabels: {
            style: {
                colors: ["#2C7E72"],
                fontSize: "9"
            },
            enabled: true,
            background: {
                enabled: false
            },

            formatter: function (val: any) {
                return `${val}${weight_unit}`;
            },
            offsetX: 0,
            offsetY: -20
        },
        markers: {
            colors: "green",
            size: 8,
            offsetX: 0,
            offsetY: 0,
        },
        yaxis: {
            show: false
        },
        grid: {
            width: "100%",
            clipMarkers: false,
            show: false,
            xaxis: {
                lines: {
                    show: true
                }
            },

            yaxis: {
                lines: {
                    show: false
                }
            },
            borderColor: "green",
            padding: {
                top: 10,
                right: 15,
                bottom: 10,
                left: 15
            },
            strokeDashArray: 10
        }
    }
    const series = [
        {
            data: weight_loss_data
        }
    ];
    return (
        <>
            <div style={{ margin: 30, width: "110%" }}>
                {
                    weight_loss_data.length ?
                        <Chart options={chartoptions} series={series} type="area" width="100%" />
                        : (
                            <div className="chart-wrapper">
                                <Loader isVisible />
                            </div>
                        )
                }
            </div>
            {
                greeting && (<>
                    <h1 className="page-title">Based on your answers, you’ll be <span style={{ color: "#DA7D9D" }}>{target_weight}</span> by <span style={{ color: "#DA7D9D" }}>{target_month}</span></h1>
                    <p style={{ width: "100%" }} className="static-description">{description}</p>  </>)
            }
            <Button
                onClick={() => goNext()}
                data-test-id="NextButton"
                className='btn-nextSecondary'
            >Next</Button>
        </>

    );

}
export const webStyles = {
    GreetWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "10px 0px",
        margin: "0px 10px",
        width: "30%",
    },
    nextButtonWrapper: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        marginTop: "20px"

    },
    nextButton: {
        fontSize: 16,
        padding: '6px 12px',
        borderRadius: "10px",
        lineHeight: 2,
        width: "100%",
        backgroundColor: '#0063cc',
        height: "56px"
    }
}


