import { WeightUnit } from "../../surveys2/src/types";

export interface ValidationResult {
    errorMessage: null | string;
    validMessage: string;
    isValid: boolean;
    errorDescription: string;
}

export const validationUnitParams = {
    weight: {
        Kg: {
            minCurrent: 41,
            minTarget: 40,
            maxCurrent: 183,
            maxTarget: 182,
            minDiff: 1,
        },
        Lb: {
            minCurrent: 90,
            minTarget: 88,
            maxCurrent: 403,
            maxTarget: 401,
            minDiff: 2,
        }
    },
    height: {
        Cm: {
            min: 91,
            max: 209,
        },
        Ft: {
            minFt: 3,
            maxFt: 6,
            maxIn: 11,
        }
    }
}

export const validateionMessages = {
    height: {
        valid: `You're on the right track! Your height value is valid. We are excited to see how you progress on your fitness journey.`,
        ft: {
            max: 'Maximum height should be 6Ft 11 inch',
            min: 'Minimum height should be 3Ft 0 inch.',
            negative: 'Height cannot be negative.',
            in: 'Inches cannot be bigger than 11'
        },
        cm: {
            max: 'Maximum height should be 209cm.',
            min: 'Minimum height should be 91cm.',
            negative: 'Height cannot be negative.',
        }
    },
    weight: {
        valid: `You're on the right track! Your weight value is valid. We are excited to see how you progress on your fitness journey.`,
        current: {
            Lb: {
                min: 'Minimum weight should be 90Lb.',
                max: 'Maximum weight should be 403Lb.',
                negative: 'Weight cannot be negative.',
            },
            Kg: {
                min: `Minimum weight should be 41Kg.`,
                max: 'Maximum weight should be 183Kg.',
                negative: 'Weight cannot be negative.'
            }
        },
        target: {
            smallDifferenceValid: `You're on your way to a healthier you!`,
            smallDifference: `You're on the right track! Your target weight is close to your current weight, so just keep doing what you're doing and you'll be there in no time.`,
            overweight: `Your goal weight is higher than your current weight. Aptfit is for weight loss only. We're always working on new features, so stay tuned!`,
            Lb: {
                min: 'Minimum weight should be 88Lb.',
                max: 'Maximum weight should be 401Lb.',
                negative: 'Weight cannot be negative.',
            },
            Kg: {
                min: `Minimum weight should be 40Kg.`,
                max: 'Maximum weight should be 182Kg.',
                negative: 'Weight cannot be negative.'
            }
        },
    }
}

export function validateCm(height: number) {
    const { min, max } = validationUnitParams.height.Cm;
    const { max: maxErr, min: minErr, negative } = validateionMessages.height.cm;
    if (height === 0) {
        return '';
    }
    if (height < 0) {
        return negative;
    }
    if (height < min) {
        return minErr;
    }
    if (height > max) {
        return maxErr;
    }
    return null;
}

export function validateFt(feet: number, inches: number) {
    const feetRounded = Math.round(feet);
    const inchesRounded = Math.round(inches);
    const { minFt, maxFt, maxIn } = validationUnitParams.height.Ft;
    const { max, min, negative, in: inch } = validateionMessages.height.ft;

    if (feetRounded === 0 && inchesRounded === 0) {
        return '';
    }
    if (inchesRounded < 0 || feetRounded < 0) {
        return negative;
    }
    if (feetRounded < minFt) {
        return min;
    }
    if (feetRounded > maxFt) {
        return max;
    }
    if (inchesRounded > maxIn) {
        return inch;
    }

    return null;
}

export function validateWeight(weight: number, unit: WeightUnit): ValidationResult {
    const { minCurrent, maxCurrent } = validationUnitParams.weight[unit];
    const { max, min, negative } = validateionMessages.weight.current[unit];
    const validationResult: ValidationResult = {
        isValid: false,
        validMessage: '',
        errorDescription: '',
        errorMessage: null,
    }
    if (weight < 0) {
        validationResult.errorMessage = negative;
        validationResult.isValid = false;
    } else if (weight < minCurrent && weight !== 0) {
        validationResult.errorMessage = min;
        validationResult.isValid = false;
    } else if (weight > maxCurrent) {
        validationResult.errorMessage = max;
        validationResult.isValid = false;
    } else if (weight !== 0) {
        validationResult.isValid = true;
        validationResult.validMessage = validateionMessages.weight.valid;
    }
    return validationResult;
}

export function validateTargetWeight(targetWeight: number, currentWeight: number, unit: WeightUnit): ValidationResult {
    const { minTarget, maxTarget, minDiff } = validationUnitParams.weight[unit];
    const { max, min, negative } = validateionMessages.weight.target[unit];
    const { overweight, smallDifference, smallDifferenceValid } = validateionMessages.weight.target;

    const validationResult: ValidationResult = {
        isValid: true,
        validMessage: '',
        errorDescription: '',
        errorMessage: null,
    }
    if (targetWeight === 0) {
        validationResult.isValid = false;
    } else if (targetWeight < 0) {
        validationResult.errorMessage = negative;
        validationResult.isValid = false;
    } else if (targetWeight < minTarget) {
        validationResult.errorMessage = min;
        validationResult.isValid = false;
    } else if (targetWeight > maxTarget) {
        validationResult.errorMessage = max;
        validationResult.isValid = false;
    } else if (targetWeight > currentWeight) {
        validationResult.isValid = false;
        validationResult.errorDescription = overweight;
    } else if ((currentWeight - targetWeight) < minDiff) {
        validationResult.isValid = false;
        validationResult.errorDescription = smallDifference;
        validationResult.validMessage = smallDifferenceValid;
    }
    return validationResult;
}

//to bypass memory heap error if there is too much conditions in components
export function oneOf<T>(val1: T, val2: T, condition: any) {
    return condition ? val1 : val2;
}

//to bypass memory heap error if there is too much conditions in components
export function getOrDefault<T>(val: T | undefined | null, defaultVal: T) {
    return val ?? defaultVal;
}

//to bypass memory heap error if there is too much conditions in components
export function toNumber(num: any) {
    return Number(num) || 0;
}

export function roundFeetIfOverMaxIn(ft?: number, inch?: number) {
    if (!ft && !inch) {
        return {}
    }

    const inchRounded = Math.round(inch as number);
    if (inchRounded > validationUnitParams.height.Ft.maxIn) {
        return {
            feet: ft as number + 1,
            inches: 0,
        }
    }

    return {
        feet: ft,
        inches: inch,
    }
}
