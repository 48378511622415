import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Attributes, WeightUnit } from "../../surveys2/src/types";
import { ValidationResult, getOrDefault, oneOf, toNumber, validateTargetWeight, validateWeight } from "./utils";

const configJSON = require("./config");

export interface WeightFormProps {
    questions: any;
    step: number;
    attributes: Attributes;
    setStepFunc: (step: number) => void;
    updateQuestionsAction: (questions: any[]) => void;
    isTarget: boolean;
}

interface WeightConversionResponse {
    weight_kg?: number;
    weight_lb?: number;
}

interface S {
    weight: number;
    unit: WeightUnit;
    isFetching: boolean;
    isValid: boolean;
    validMessage: string;
    errorMessage?: string | null;
    targetWeight: number;
    errorDescription: string;
}

interface SS {
    id: any;
}

export default class HeightFormController extends BlockComponent<WeightFormProps, S, SS> {
    private messageId: string = '';
    private targetWeightMessage: string = '';
    constructor(props: WeightFormProps) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            isFetching: false,
            ...this.getUpdatedState(),
        }
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.messageId) {
                this.handleWeightConversion(responseJson, errorReponse);
            }
            if (apiRequestCallId === this.targetWeightMessage) {
                this.handleWeightConversion(responseJson, errorReponse, true);
            }
        }
    }

    componentDidUpdate(prevProps: Readonly<WeightFormProps>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        const prevStep = prevProps.step;
        const currStep = this.props.step;
        if (currStep !== prevStep) {
            this.setState({
                isFetching: false,
                ...this.getUpdatedState(),
            })
        }
        if (this.state.weight !== prevState.weight) {
            this.updateQuestion(Math.round(this.state.weight), this.props.step);
        }

        if (this.state.targetWeight !== prevState.targetWeight) {
            const isWeightForm = !this.props.isTarget;
            this.updateQuestion(Math.round(this.state.targetWeight), this.props.step + Number(isWeightForm));
        }
    }

    getUnits() {
        const { unit: currentUnit, isFetching } = this.state;
        const { isTarget } = this.props;
        return ['Lb', 'Kg'].map((unit) => ({
            title: unit,
            isSelected: currentUnit === unit,
            isDisabled: isTarget || isFetching,
            handleClick: () => this.changeWeightUnit(unit as WeightUnit),
        }));
    }

    handleSubmit() {
        this.props.setStepFunc(1);
    }

    private updateQuestion(weight: number, step: number) {
        const updatedQuestions = this.props.questions.map((question: any, index: number) => {
            if (index !== step) {
                return question;
            }

            const option = question.attributes.options[0];
            option.answer = weight;
            option.is_selected=true
            option.answer_data=weight;
            option.weight_unit = this.state.unit;
            return question;
        });

        this.props.updateQuestionsAction(updatedQuestions);
    }

    handleInput(input: number, isTarget?: boolean) {
        const validationResult = isTarget ?
            validateTargetWeight(input, this.state.weight, this.state.unit)
            : validateWeight(input, this.state.unit);
        const newState = isTarget ? { targetWeight: input } : { weight: input }
        this.setState({
            ...this.state,
            ...newState,
            ...validationResult,
        });
    }

    changeWeightUnit(weightUnit: WeightUnit) {
        if (this.state.unit === weightUnit) {
            return;
        }

        if (!this.state.weight) {
            this.setState(
                {
                    ...this.state,
                    unit: weightUnit,
                },
            );

            return;
        }

        this.sendConverRequest(this.state.weight, (id) => this.messageId = id);
        this.sendConverRequest(this.state.targetWeight, (id) => this.targetWeightMessage = id);
        this.setState({
            ...this.state,
            isFetching: true,
        });
    }

    private sendConverRequest(weighgt: number, assignMessage: (messageId: string) => void) {
        const convertWeightApiId = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );

        assignMessage(convertWeightApiId.messageId);

        const urlParams = new URLSearchParams();
        const key = `weight_${this.state.unit}`.toLowerCase();
        urlParams.append(key, weighgt.toString());

        convertWeightApiId.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_cfneededforunitconversion5/unit_conversions/convert_weight?" + urlParams.toString()
        );

        convertWeightApiId.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(convertWeightApiId.id, convertWeightApiId);
    }

    private handleWeightConversion({ weight_kg, weight_lb }: WeightConversionResponse, errorResponse: unknown, isTarget?: boolean) {
        if (errorResponse) {
            console.error('convert-weight-error', errorResponse);
            this.setState(
                { ...this.state, isFetching: false }
            )
            return;
        }

        const weight = weight_kg ?? weight_lb ?? this.state.weight;
        const newState = oneOf<any>({ targetWeight: weight }, { weight }, isTarget);
        this.setState({
            ...this.state,
            ...newState,
            isFetching: false,
            unit: oneOf(this.state.unit, this.getOppositeWeightUnit(), isTarget),
        });
    }

    private getOppositeWeightUnit(): WeightUnit {
        const unit = this.state.unit;
        if (unit === 'Kg') {
            return 'Lb';
        }

        return 'Kg';
    }

    private getUpdatedState() {
        const { attributes, questions, step, isTarget } = this.props;
        const option = attributes.options[0];
        const value = toNumber(option.answer);
        const unit = option.weight_unit;

        let state: any;
        let error: ValidationResult;
        if (isTarget) {
            const prevOption = questions[step - 1].attributes.options[0];
            const prevWeigh = toNumber(prevOption.answer);
            const prevUnit = getOrDefault<WeightUnit>(questions[step - 1].attributes.options[0].weight_unit, 'Lb');
            state = {
                targetWeight: value,
                unit: prevUnit,
                weight: prevWeigh,
            }
            error = validateTargetWeight(value, prevWeigh, prevUnit);
        } else {
            const currUnit = getOrDefault(unit, 'Lb');
            const nextOption = questions[step + 1].attributes.options[0];
            const nextWeigh = toNumber(nextOption.answer);
            state = {
                weight: value,
                unit: currUnit,
                targetWeight: nextWeigh,
            }
            error = validateWeight(value, currUnit);
        }

        state.isValid = error.isValid;
        state.errorMessage = error.errorMessage;
        state.validMessage = error.validMessage;
        state.errorDescription = error.errorDescription

        return state;
    }
}