import React from "react";
import { Box, Button } from "@material-ui/core";
import QuestionFormWrapper from "../../surveys2/src/QuestionFormWrapper.web";
import EmailFormController, { EmailFormProps } from "./EmailFormController";

export default class EmailForm extends EmailFormController {
  constructor(props: EmailFormProps) {
    super(props);
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  render() {
    const { question, description } = this.props.attributes;

    return (
      <QuestionFormWrapper question={question}>
        <div
          data-testid={"emailForm"}
          className="options"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          <div>
            <p
              style={{
                fontFamily: "Inter",
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "22px",
                letterSpacing: "0px",
                textAlign: "left",
                marginBottom: "5px"
              }}
            >
              Email
            </p>
            <input
              value={this.props.email}
              onChange={e => this.props.setEmail(e.target.value)}
              onBlur={this.handleBlur}
              style={{
                height: "56px",
                borderRadius: "8px",
                border: "1px solid",
                borderColor: this.state.errorMessage ? "red" : " #CBD5E1",
                width: "100%",
                padding: "17px 8px",
                font: "Inter",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "22px"
              }}
              autoFocus
              placeholder="Your email"
            />
          </div>
          {this.state.errorMessage && (
            <p
              data-testid="errMsg"
              style={{
                color: "red",
                fontSize: "16px",
                lineHeight: "22px",
                textAlign: "center",
                marginBottom: 0,
                marginTop: "18px"
              }}
            >
              {this.state.errorMessage}
            </p>
          )}
          <Box
            style={{
              width: "100%",
              marginBottom: "0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: "40px 0"
            }}
          >
            <p
              className="page-description"
              data-testid={"text-desc"}
              style={{ margin: 0, padding: 0 }}
            >
              {description}
            </p>
            <p
              className="page-description"
              data-testid={"text-desc"}
              style={{ margin: 0, padding: 0 }}
            >
              By clicking "Next" below you agree to Aptfit's{" "}
              <span
                data-testid="TaC"
                onMouseDown={() => this.props.onLinkClick("TaC")}
                style={{
                  textDecoration: "underline",
                  color: "#59559F",
                  cursor: "pointer"
                }}
              >
                Terms & Conditions
              </span>{" "}
              and
              <span
                onMouseDown={() => this.props.onLinkClick("Privacy")}
                style={{
                  textDecoration: "underline",
                  color: "#59559F",
                  cursor: "pointer"
                }}
              >
                {" "}
                Privacy Notice.
              </span>
            </p>
          </Box>
          <Button
            disabled={!this.state.isValid}
            data-testid={"next-btn"}
            onClick={this.handleSubmit}
            className="btn-nextSecondary"
          >
            Next
          </Button>
        </div>
      </QuestionFormWrapper>
    );
  }
}
