import React, { FC, useState } from "react";
import { Attributes } from "../../surveys2/src/types";
import { SelectedLink } from "../../termsconditions/src/TermsConditionsContainerController";
import { useDispatch, useSelector } from "react-redux";
import TermsConditionsContainer from "../../termsconditions/src/TermsConditionsContainer.web";
import EmailForm from "./EmailForm.web";
import { updateEmailAction, updateShowBackButton } from "../../../framework/src/Redux/Actoins/QuestionActions";

export interface EmailViewProps {
    step: number;
    attributes: Attributes;
    setStepFunc: (step: number) => void;
}

const EmailFormView: FC<EmailViewProps> = ({
    step,
    attributes,
    setStepFunc
}) => {
    const dispatch = useDispatch();
    const email = useSelector<any, string>(state => state.QuestionReducer.email);
    const showBackButton = useSelector<any, boolean>(state => state.QuestionReducer.showBackButton);
    const [selectedLink, setSelectedLink] = useState<SelectedLink | ''>('');
    const handleLinkClick = (link: SelectedLink) => {
        setSelectedLink(link);
        dispatch(updateShowBackButton(false));
    };
    const handleLinkClose = () => {
        setSelectedLink('');
        dispatch(updateShowBackButton(true));
    };
    const handleEmailChange = (e: string) => dispatch(updateEmailAction(e))

    if (selectedLink) {
        return <TermsConditionsContainer selectedLink={selectedLink} onClose={handleLinkClose} />
    }

    if (!showBackButton) {
        dispatch(updateShowBackButton(true));
        return null;
    }

    return <EmailForm
        onLinkClick={handleLinkClick}
        email={email}
        setEmail={handleEmailChange}
        setStepFunc={setStepFunc}
        step={step}
        attributes={attributes}
    />

}

export default EmailFormView;