import React from "react";
import TermsConditionsContainerConroller, {
    TermsConditionProps,
} from "./TermsConditionsContainerController";
import QuestionFormWrapper from "../../surveys2/src/QuestionFormWrapper.web";
import { Box, Button } from "@material-ui/core";
import "../assets/styles/index.css";

export default class TermsConditionsContainer extends TermsConditionsContainerConroller {
    constructor(props: TermsConditionProps) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
    }

    render() {
        const { text, title } = this.state;
        return (
            <QuestionFormWrapper question={title}>
                <Box
                    data-testid={'termsconditions'}
                    className="tac-description-container"
                >
                    <Box
                        className="tac-description"
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                </Box>
                <Button
                    data-testid='closeBtn'
                    style={{
                        width: "100%",
                        maxWidth: "520px",
                        marginTop: "30px",
                        fontFamily: `'Inter', sans-serif`,
                        backgroundColor: "#59559F",
                        color: "#FFFFFF",
                        fontWeight: 600,
                        padding: '10px 16px',
                        borderRadius: '8px',
                        textTransform: 'capitalize',
                    }}
                    onClick={this.handleClose}
                >
                    Close
                </Button>
            </QuestionFormWrapper>
        );
    }
}
