import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Attributes, HeightUnit } from "../../surveys2/src/types";
import { oneOf, validateionMessages, validateCm, validateFt, roundFeetIfOverMaxIn } from "./utils";

const configJSON = require("./config");

export interface HeightFormProps {
    questions: any;
    step: number;
    attributes: Attributes;
    setStepFunc: (step: number) => void;
    updateQuestionsAction: (questions: any[]) => void;
}

interface S {
    currentIn: number;
    currentFt: number;
    currentCm: number;
    unit: HeightUnit;
    isValid: boolean;
    validMessage: string;
    errorMessage?: string | null;
    isFetching: boolean;
}

interface SS {
    id: any;
}

interface HeightInput {
    cm?: number;
    feet?: number;
    inches?: number;
}

interface HeightConversionResponse {
    height_feet?: number;
    height_inches?: number;
    height_cm?: number;
}

export default class HeightFormController extends BlockComponent<HeightFormProps, S, SS> {
    private messageId: string = '';
    private touched: boolean = true;

    constructor(props: HeightFormProps) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            isFetching: false,
            ...this.getInitialState(),
        }
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async componentDidUpdate(prevProps: Readonly<HeightFormProps>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        if (prevState.currentCm !== this.state.currentCm) {
            const error = this.validateHeight({
                cm: this.state.currentCm
            });
            this.setState({
                ...this.state,
                errorMessage: error,
                isValid: error === null,
                validMessage: oneOf(validateionMessages.height.valid, '', error === null),
            });
            this.updateQuestion();
        }
        if (
            prevState.currentFt !== this.state.currentFt
            || prevState.currentIn !== this.state.currentIn) {
            const error = this.validateHeight({
                feet: this.state.currentFt,
                inches: this.state.currentIn
            });
            this.setState({
                ...this.state,
                errorMessage: error,
                isValid: error === null,
                validMessage: oneOf(validateionMessages.height.valid, '', error === null),
            });
            this.updateQuestion();
        }
        if (this.state.unit !== prevState.unit) {
            this.updateQuestion();
        }
    }
    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.messageId) {
                this.handleHeightConversion(responseJson, errorReponse);
            }
        }
    }

    handleSubmit() {
        this.props.setStepFunc(1);
    }

    private updateQuestion() {
        const updatedQuestions = this.props.questions.map((question: any, index: number) => {
            if (index !== this.props.step) {
                return question;
            }

            const option = question.attributes.options[0];
            if (this.state.unit === 'Cm') {
                option.answer = Math.round(this.state.currentCm);
                option.answer_data = Math.round(this.state.currentCm);
                option.is_selected=true;
                option.height_unit = 'Cm';
            }
            if (this.state.unit === 'Ft') {
                option.answer = Math.round(this.state.currentFt);
                option.inches = Math.round(this.state.currentIn);
                option.answer_data = Math.round(this.state.currentFt)+'.'+Math.round(this.state.currentIn);
                option.is_selected=true;
                option.height_unit = 'Ft';
            }
            option.unit = this.state.unit;
            return question;
        });

        this.props.updateQuestionsAction(updatedQuestions);
    }

    handleInput(input: HeightInput) {
        this.touched = true;

        let newWeight: Partial<S> = {};

        if (input.cm != undefined) {
            newWeight.currentCm = input.cm;
        } else if (input.feet != undefined) {
            newWeight.currentFt = input.feet;
        } else {
            newWeight.currentIn = input.inches;
        }
        this.setState({
            ...this.state,
            ...newWeight,
        })
    }


    getUnits() {
        const { unit: currentUnit } = this.state;
        return ['Ft', 'Cm'].map((unit) => ({
            title: unit,
            isSelected: currentUnit === unit,
            isDisabled: this.state.isFetching,
            handleClick: () => this.changeHeightUnit(unit as 'Cm' | 'Ft'),
        }));
    }

    private validateHeight(input: HeightInput) {
        if (this.state.unit === 'Cm') {
            return validateCm(input.cm ?? 0);
        }
        return validateFt(input.feet ?? this.state.currentFt, input.inches ?? this.state.currentIn);
    }

    changeHeightUnit(unit: HeightUnit) {
        if (unit === this.state.unit) {
            return;
        }

        if (!this.touched) {
            this.setState(
                {
                    ...this.state,
                    unit,
                },
            );
            return;
        }

        if (
            !this.state.currentCm && this.state.unit === 'Cm'
            || !this.state.currentFt && !this.state.currentIn && this.state.unit === 'Ft'
        ) {
            this.setState(
                {
                    ...this.state,
                    unit: unit,
                    currentCm: 0,
                    currentFt: 0,
                    currentIn: 0,
                },
            );
            return;
        }
        const convertHeightApiId = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.messageId = convertHeightApiId.messageId;

        const urlParams = this.getHeightUrlParams();

        convertHeightApiId.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            "bx_block_cfneededforunitconversion5/unit_conversions/convert_height?" + urlParams.toString()
        );

        convertHeightApiId.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(convertHeightApiId.id, convertHeightApiId);

        this.setState({
            ...this.state,
            isFetching: true,
        });
    }

    private getHeightUrlParams() {
        const urlParams = new URLSearchParams();
        if (this.state.unit === 'Cm') {
            urlParams.append('height_cm', this.state.currentCm.toString());
            return urlParams;
        }

        urlParams.append('height_feet', this.state.currentFt.toString());
        urlParams.append('height_inches', this.state.currentIn?.toString() ?? 0);
        return urlParams;
    }

    private handleHeightConversion(response: HeightConversionResponse, errorResponse: unknown) {
        this.touched = false;

        if (errorResponse) {
            console.error('convert-height-error', errorResponse);
            this.setState(
                { ...this.state, isFetching: false }
            )
            return;
        }
        const { feet, inches } = roundFeetIfOverMaxIn(response.height_feet, response.height_inches);
        this.setState({
            ...this.state,
            isFetching: false,
            currentCm: response.height_cm ?? this.state.currentCm,
            currentFt: feet ?? this.state.currentFt,
            currentIn: inches ?? this.state.currentIn,
            unit: this.getOppositeHeightUnit(),
        });
    }

    private getOppositeHeightUnit(): HeightUnit {
        const unit = this.state.unit;
        if (unit === 'Cm') {
            return 'Ft';
        }

        return 'Cm';
    }

    private getInitialState(): Omit<S, 'isFetching'> {
        const { attributes } = this.props;
        const option = attributes.options[0];
        const value = option.answer;
        const unit = option.height_unit;

        let state: any;
        let error: string | null = null;
        if (unit == 'Cm') {
            state = {
                currentCm: Number(value) || 0,
                currentFt: 0,
                currentIn: 0,
                unit: 'Cm'
            }
        } else {
            state = {
                currentCm: 0,
                currentFt: Number(value) || 0,
                currentIn: Number(option.inches) || 0,
                unit: 'Ft'
            };
        }

        if (state.currentCm) {
            error = validateCm(state.currentCm);
        }
        if (state.currentFt) {
            error = validateFt(state.currentFt, state.currentIn);
        }
        state.isValid = error == null && Boolean(state.currentCm || state.currentFt);
        state.errorMessage = error;
        state.validMessage = oneOf(validateionMessages.height.valid, '', state.isValid);

        return state;
    }
}