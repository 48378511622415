import { Box } from "@material-ui/core";
import React, { FC, useState } from "react";
import { GenericInput } from "./GenericInput.web";
import { validationUnitParams } from "./utils";

export interface FeetInputProps {
    currentFt: number;
    currentIn: number;
    errorMessage?: string | null;
    handleFeetInput: (val: string) => void;
    handleInchesInput: (val: string) => void;
}

const FeetInput: FC<FeetInputProps> = ({
    currentFt,
    currentIn,
    errorMessage,
    handleFeetInput,
    handleInchesInput
}) => {
    const [selectedField, setSelectedField] = useState<'Ft' | 'In' | null>('Ft');
    const handleFeetChange = (val: string) => {
        handleFeetInput(val);
        const inputNumber = +val;
        if (inputNumber >= validationUnitParams.height.Ft.minFt
            && inputNumber <= validationUnitParams.height.Ft.maxFt
        ) {
            setSelectedField('In');
        }
    }

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <GenericInput
                unit={"Ft"}
                data-testid={'input-ft'}
                value={currentFt}
                isValid={!errorMessage}
                inputStyles={{ width: "50px" }}
                handleChange={handleFeetChange}
                maxLength={1}
                autofocus={selectedField === 'Ft'}
            />
            <GenericInput
                unit={"In"}
                data-testid={'input-in'}
                value={currentIn}
                isValid={!errorMessage}
                inputStyles={{ width: "75px" }}
                handleChange={(value) => handleInchesInput(value)}
                maxLength={2}
                autofocus={selectedField === 'In'}
                handleFocus={() => setSelectedField(null)}
            />
        </Box>
    );
};

export default FeetInput;
