import React from 'react'
import {
    Box,
    Slider, withStyles,
} from "@material-ui/core";
import { backIcon, leafImg } from "./assets";

export const SliderThumpComponent = (props: any) => {
    return (
        <span {...props} >
            <img
                style={{
                    height: 24,
                    width: 24,
                    borderRadius: "50%",
                    backgroundColor: "#fff"
                }}
                src={leafImg}
            />
        </span>
    )
}

interface SliderProps {
    step: number;
    goBack: () => void;
    QuestionLength?:number
}

export default function CustomSlider(props: SliderProps) {
    return (
        <Box style={{ width: "100%" }}>
            <Box sx={webStyles.sliderWrapper}>
               <Box style={{ width: "17%", marginTop: "3px" }}>
                    {props.step >0 && <img
                        onClick={() => props.goBack()}
                        data-test-id="BackImage"
                        style={webStyles.backImage}
                        src={backIcon} 
                    />}
                </Box>
                <Box style={{  width: "70%" }}>

                    <PrettoSlider
                        step={1}
                        ThumbComponent={SliderThumpComponent}
                        value={props.step}
                        max={props.QuestionLength? props.QuestionLength : 40}
                        min={1}
                        marks={true}
                    />


                </Box>
            </Box>
        </Box>
    )
}
export const webStyles = {
    sliderWrapper: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        margin: '0 auto'
    },
    backImage: {
        height: "25px",
        width: "18px",
        display: "block",
        color: "##5A55A4",
        cursor:"pointer"
    }
}
const PrettoSlider = withStyles({
    root: {
        color: "#5A55A4",
        height: 10,
        width: "100%",
        cursor: "default"
    },
    thumb: {
        height: 24,
        width: 24,
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit"
        }
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)"
    },
    track: {
        height: 13,
        borderRadius: 7
    },
    rail: {
        height: 13,
        borderRadius: 5,
        color: "lightgrey"
    },
    mark: {
        width: 0

    },
    markActive: {
        marginTop: 5,
        width: "9px",
        marginLeft: "4px"
    }
})(Slider);
