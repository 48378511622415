export enum ResponseType {
    MULTIPLE = 'multiple_choice',
    SINGLE = 'single_choice',
    SUBJECIVE = 'subjective',
    STATIC = 'static',
    EMAIL = 'email',
}

export interface Question {
    attributes: Attributes,
}
export interface Attributes {
    question_id: number;
    question: string;
    response_type: ResponseType;
    options: Option[];
    description?: string | null;
    image?: string;
    random_num?: number;
}

export interface Option {
    id: number;
    is_selected: boolean;
    title: string;
    answer?: string;
    weight_unit?: WeightUnit;
    height_unit?: HeightUnit;
    inches?: string;
    depend_opt_id?: number;
    description?: string | null;
    image?: string;
    body_type?: string; 
}

export type WeightUnit = 'Lb' | 'Kg';
export type HeightUnit = 'Cm' | 'Ft';
