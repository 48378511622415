import React from "react";

// Customizable Area Start
import { Box, Button } from "@material-ui/core";
import "../assets/styles/index.css";
// Customizable Area End

import TermsConditionsController, {
  TermsConditionProps,
} from "./TermsConditionsContainerController";
import QuestionFormWrapper from "../../surveys2/src/QuestionFormWrapper.web";
import { logoImage } from "../../surveys2/src/assets";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: TermsConditionProps) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { description, title } = this.state.tc;
    return <>
    <div className="common-wrapper-termsconditions">
    <img src={logoImage} alt="" className="logo-img" width={115} />
    <QuestionFormWrapper question={title}>
                <Box
                    data-testid={'termsconditions'}
                    className="tac-description-container-fixed"
                >
                    <Box
                        className="tac-description"
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                </Box>
               
            </QuestionFormWrapper>
            </div>
    </>
   
    // Customizable Area End
  }
}

// Customizable Area Start

// Customizable Area End
