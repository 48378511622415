import React from "react";
import WeightFormController, { WeightFormProps } from "./WeightFormController";
import SubjectiveView from "./SubjectiveView.web";
import { GenericInput } from "./GenericInput.web";

export default class WeightForm extends WeightFormController {
    constructor(props: WeightFormProps) {
        super(props);
        this.changeWeightUnit = this.changeWeightUnit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.getUnits = this.getUnits.bind(this);
    }

    render() {
        const [left, right] = this.getUnits();
        const { attributes, isTarget } = this.props;
        const { isValid, unit, errorMessage, isFetching, validMessage, targetWeight, weight, errorDescription } = this.state;
        const weightValue = isTarget ? targetWeight : weight;
        return <SubjectiveView
            left={left}
            right={right}
            attributes={attributes}
            errorMessage={errorMessage}
            isValid={isValid}
            isFetching={isFetching}
            validMessage={validMessage}
            handleSubmit={this.handleSubmit}
            errorDescription={errorDescription}
        >
            <GenericInput
                data-testid={'input-weight-target-' + isTarget}
                unit={unit}
                value={weightValue}
                isValid={!errorMessage && !errorDescription}
                autofocus
                maxLength={3}
                handleChange={(value) => this.handleInput(+value, isTarget)}
                step={this.props.step}
            />
        </SubjectiveView>;
    }
}
