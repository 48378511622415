import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
    step: number;
    questions: any[];
    setStepFunc: (step: number) => void;
    attributes:any;
    updateQuestionsAction: (questions: any[]) => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
 
  // Customizable Area Start
  progress1: number,
  progress2: number,
  progress3: number,
  progress4: number,
  displayText:string;
  isModalOpen: boolean,
  seondModal:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DynamiccontentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  interval1: any;
  interval2: any;
  interval3: any;
  interval4: any;
  getUserDataApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.interval1 = null;
    this.interval2 = null;
    this.interval3 = null;
    this.interval4 = null;
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      progress1: 0,
      progress2: 0,
      progress3: 0,
      progress4: 0,
      displayText: 'Comparing the demographic data...',
      isModalOpen: false,
      seondModal:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
   
    // Customizable Area End
  }
  async componentDidMount() {
    const currentquest=this.props.attributes.question_id;
 if(currentquest==47){
    this.startProgress1(); }
  else if (currentquest==48) {
    this.startProgress2();
    this.setState({progress1:100})
  } else if (currentquest==49) {
    this.setState({progress1:100,progress2:100})
    this.startProgress3();
  } 

  }

  async componentWillUnmount() {
    clearInterval(this.interval1);
    clearInterval(this.interval2);
    clearInterval(this.interval3);
    clearInterval(this.interval4);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
     
      if (apiRequestCallId === this.getUserDataApiCallId) {
        this.setState({ seondModal: true });
        this.startProgress4();
        console.log(responseJson)
           }
     
    }
    // Customizable Area End
  }

 

  // Customizable Area Start
  startProgress1 = () => {
    this.interval1 = setInterval(() => {
      this.setState((prevState) => {
        return (prevState.progress1 < 100) ? {
          progress1: prevState.progress1 + 1,
          displayText: prevState.progress1 >= 0 && prevState.progress1 <= 64
            ? 'Comparing the Demographic Data...' 
            : 'Evaluating Body Composition...'
        } : null;
      }, () => {
        if (this.state.progress1 >= 100) {
          clearInterval(this.interval1);
          this.setState({ isModalOpen: true });
        }
      });
    }, 100);
  };

  startProgress2 = () => {
    this.interval2 = setInterval(() => {
      this.setState((prevState) => {
        return (prevState.progress2 < 100) ? {
          progress2: prevState.progress2 + 1,
          displayText: prevState.progress2 >= 0 && prevState.progress2 <= 40
            ? 'Matching Behavior Trends...'
            : 'Building Habit Profile...'
        } : null;
      }, () => {
        if (this.state.progress2 >= 100) {
          clearInterval(this.interval2);
          this.setState({ isModalOpen: true });
        }
      });
    }, 100);
  };
  startProgress3 = () => {
    this.interval3 = setInterval(() => {
      this.setState((prevState) => {
        return (prevState.progress3 < 100) ? {
          progress3: prevState.progress3 + 1,
          displayText: prevState.progress3 >= 0 && prevState.progress3 <= 80
            ? 'Evaluating Diet Preferences...'
            : 'Predicting Future Results...'
        } : null;
      }, () => {
        if (this.state.progress3 >= 100) {
          clearInterval(this.interval3);
          this.setState({ isModalOpen: true });
        }
      });
    }, 100);
  };
  startProgress4 = () => {
    this.interval4 = setInterval(() => {
      this.setState((prevState) => {
        return (prevState.progress4 < 100) ? {
          progress4: prevState.progress4 + 1,
    
        } : null;
      }, () => {
        if (this.state.progress4 >= 100) {
          clearInterval(this.interval4);
          
        }
      });
    }, 100);
  };
  getUserDataa=()=>{
    const myHeader = new Headers();
    const token=localStorage.getItem('token')
    myHeader.append("token", `${token}`);
    myHeader.append("Content-Type", "application/json");
   
    const httpBody = this.props.questions;
  
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.getUserDataApiCallId = requestMessage.messageId;
  
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_surveys2/survey_answers`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        myHeader
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  
  }
  closeModal = () => {
    this.setState({ isModalOpen: false }, () => {
      if (this.state.progress1 >= 100 && this.state.progress2 < 100) {
        this.startProgress2();
        this.setState({ isModalOpen: false });
      } else if (this.state.progress2 >= 100 && this.state.progress3 < 100) {
        this.startProgress3();
        this.setState({ isModalOpen: false });
       
      } else if (this.state.progress3 >= 100 ) {
        this.getUserDataa();
        
      }
    });
  };
  
  // Customizable Area End
}
