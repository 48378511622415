import React, { ReactNode } from "react";

interface Props {
    question: string;
    description?: string | null;
    children: ReactNode;
}

export default function QuestionFormWrapper({ question, description, children }: Props) {
    return (
        <>
            <h1 className="page-title">{question}</h1>
            {description && <p className='page-description'>{description}</p>}
            {children}
        </>
    )
}