import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Attributes } from "../../surveys2/src/types";
import { SelectedLink } from "../../termsconditions/src/TermsConditionsContainerController";
import { validateEmail } from "./utils";

const configJSON = require("./config");

export interface EmailFormProps {
    step: number;
    attributes: Attributes;
    setStepFunc: (step: number) => void;
    email: string;
    setEmail: (email: string) => void;
    onLinkClick: (link: SelectedLink) => void;
}

interface S {
    isValid: boolean;
    errorMessage: string;
}

interface SS { }

export interface SignUpSuccessResponse {
    data: {
        id: string;
        type: string;
        attributes: {
            first_name: string | null;
            last_name: string | null;
            full_phone_number: string | null;
            country_code: string | null;
            phone_number: string | null;
            email: string;
            activated: boolean;
        }
    },
    meta: {
        token: string;
    }
}

export interface SignUpErrorResponse {
    errors: [
        {
            account: string
        }
    ]
}

export default class EmailFormController extends BlockComponent<EmailFormProps, S, SS>{
    constructor(props: EmailFormProps) {
        super(props);
        this.receive = this.receive.bind(this);
        this.state = {
            isValid: false,
            errorMessage: '',
        }
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        const { email } = this.props;
        if (email) {            
            const validation = validateEmail(email);
            this.setState({
                ...this.state,
                ...validation,
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<EmailFormProps>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        if (prevProps.email !== this.props.email) {
            const { email } = this.props;
            this.validateEmail(email);
        }
    }

    async componentWillUnmount() {
        this.props.setEmail(this.props.email.trim());
    }

    private validateEmail(email: string) {
        const validation = validateEmail(email);
        this.setState({
            ...this.state,
            ...validation,
        });
    }

    handleInput(email: string) {
        this.props.setEmail(email);
    }

    handleBlur() {
        setTimeout(() => this.validateEmail(this.props.email), 100);
    }

    handleSubmit() {
     localStorage.setItem('userMail',this.props.email)
        this.props.setStepFunc(1);
    }
}