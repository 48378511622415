Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "multipageforms2";
exports.labelBodyText = "multipageforms2 Body";
exports.getQuestionsApiEndPoint = "bx_block_surveys2/survey_questions";
exports.btnExampleTitle = "CLICK ME";
 exports.list={
  "questions": {
      "data": [
          {
              "id": "1",
              "type": "survey_question",
              "attributes": {
                  "question_id": 1,
                  "question": "What is your goal?",
                  "dependent_on": "both",
                  "response_type": "single_choice",
                  "description": null,
                  "response_type_id": 2,
                  "image": "",
                  "options": [
                      {
                          "id": 1,
                          "title": "Lose weight",
                          "is_selected": false
                      },
                      {
                          "id": 2,
                          "title": "Lose belly fat",
                          "is_selected": false
                      },
                      {
                          "id": 3,
                          "title": "Get lean",
                          "is_selected": false
                      }
                  ],
                  "meal_types": []
              }
          },
          {
              "id": "2",
              "type": "survey_question",
              "attributes": {
                  "question_id": 2,
                  "question": "What's your age?",
                  "dependent_on": "both",
                  "response_type": "single_choice",
                  "description": null,
                  "response_type_id": 2,
                  "image": "",
                  "options": [
                      {
                          "id": 4,
                          "title": "Under 20's",
                          "is_selected": false
                      },
                      {
                          "id": 5,
                          "title": "20s",
                          "is_selected": false
                      },
                      {
                          "id": 6,
                          "title": "30s",
                          "is_selected": false
                      },
                      {
                          "id": 7,
                          "title": "40s",
                          "is_selected": false
                      },
                      {
                          "id": 8,
                          "title": "50s",
                          "is_selected": false
                      },
                      {
                          "id": 9,
                          "title": "60+",
                          "is_selected": false
                      }
                  ],
                  "meal_types": []
              }
          },
          {
              "id": "3",
              "type": "survey_question",
              "attributes": {
                  "question_id": 3,
                  "question": "What is your sex?",
                  "dependent_on": "both",
                  "response_type": "single_choice",
                  "description": "Sex is a required input to calculate your calorie budget. If in doubt, select the sex most closely aligned with your hormonal profile",
                  "response_type_id": 2,
                  "image": "",
                  "options": [
                      {
                          "id": 10,
                          "title": "Male",
                          "is_selected": false
                      },
                      {
                          "id": 11,
                          "title": "Female",
                          "is_selected": false
                      },
                      {
                          "id": 12,
                          "title": "Prefer not to say",
                          "is_selected": false
                      }
                  ],
                  "meal_types": []
              }
          },
          {
              "id": "4",
              "type": "survey_question",
              "attributes": {
                  "question_id": 4,
                  "question": "Are you at risk of any of the following?",
                  "dependent_on": "both",
                  "response_type": "multiple_choice",
                  "description": null,
                  "response_type_id": 1,
                  "image": "",
                  "options": [
                      {
                          "id": 13,
                          "title": "Heart disease or stroke",
                          "is_selected": false
                      },
                      {
                          "id": 14,
                          "title": "High blood pressure",
                          "is_selected": false
                      },
                      {
                          "id": 15,
                          "title": "NASH",
                          "is_selected": false
                      },
                      {
                          "id": 16,
                          "title": "Diabetes",
                          "is_selected": false
                      },
                      {
                          "id": 17,
                          "title": "Osteoarthritis",
                          "is_selected": false
                      },
                      {
                          "id": 18,
                          "title": "Depression",
                          "is_selected": false
                      },
                      {
                          "id": 19,
                          "title": "None",
                          "is_selected": false
                      }
                  ],
                  "meal_types": []
              }
          },
          {
              "id": "5",
              "type": "survey_question",
              "attributes": {
                  "question_id": 5,
                  "question": "Are you breastfeeding?",
                  "dependent_on": "female",
                  "response_type": "single_choice",
                  "description": "If yes, we will adjust the calory budget accordingly.",
                  "response_type_id": 2,
                  "image": "",
                  "options": [
                      {
                          "id": 20,
                          "title": "Yes",
                          "is_selected": false
                      },
                      {
                          "id": 21,
                          "title": "No",
                          "is_selected": false
                      }
                  ],
                  "meal_types": []
              }
          },
          {
              "id": "6",
              "type": "survey_question",
              "attributes": {
                  "question_id": 6,
                  "question": "We're glad you shared",
                  "dependent_on": "both",
                  "response_type": "static",
                  "description": "Muscle gain is an important goal, and at Aptfit ourmission is to help you feel healthier overall.",
                  "response_type_id": 3,
                  "image": "http://localhost:3000/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbG9KIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--52a84a1e2a0e197077c260b1b29b4c5d4cc5d8aa/corina-rainer-noWZPGv6JIU-unsplash.jpg",
                  "options": [],
                  "meal_types": []
              }
          },
          {
              "id": "7",
              "type": "survey_question",
              "attributes": {
                  "question_id": 7,
                  "question": "How familiar are you with the Mediterranean diet?",
                  "dependent_on": "both",
                  "response_type": "single_choice",
                  "description": null,
                  "response_type_id": 2,
                  "image": "",
                  "options": [
                      {
                          "id": 22,
                          "title": "Expert",
                          "is_selected": false
                      },
                      {
                          "id": 23,
                          "title": "I've heard a thing or two",
                          "is_selected": false
                      },
                      {
                          "id": 24,
                          "title": "Beginner",
                          "is_selected": false
                      }
                  ],
                  "meal_types": []
              }
          },
          {
              "id": "8",
              "type": "survey_question",
              "attributes": {
                  "question_id": 8,
                  "question": "How much time do you have for meal preparation each day?",
                  "dependent_on": "both",
                  "response_type": "single_choice",
                  "description": null,
                  "response_type_id": 2,
                  "image": "",
                  "options": [
                      {
                          "id": 25,
                          "title": "Up to 30 minutes",
                          "is_selected": false
                      },
                      {
                          "id": 26,
                          "title": "Up to 1 hour",
                          "is_selected": false
                      },
                      {
                          "id": 27,
                          "title": "More than 1 hour",
                          "is_selected": false
                      }
                  ],
                  "meal_types": []
              }
          },
          {
              "id": "9",
              "type": "survey_question",
              "attributes": {
                  "question_id": 9,
                  "question": "What's your height?",
                  "dependent_on": "both",
                  "response_type": "subjective",
                  "description": null,
                  "response_type_id": 0,
                  "image": "",
                  "options": [
                      {
                          "id": 28,
                          "title": "height",
                          "is_selected": false
                      }
                  ],
                  "meal_types": []
              }
          },
          {
              "id": "10",
              "type": "survey_question",
              "attributes": {
                  "question_id": 10,
                  "question": "What's your weight?",
                  "dependent_on": "both",
                  "response_type": "subjective",
                  "description": null,
                  "response_type_id": 0,
                  "image": "",
                  "options": [
                      {
                          "id": 29,
                          "title": "weight",
                          "is_selected": false
                      }
                  ],
                  "meal_types": []
              }
          },
          {
              "id": "11",
              "type": "survey_question",
              "attributes": {
                  "question_id": 11,
                  "question": "What's the ideal weight you want to achieve?",
                  "dependent_on": "both",
                  "response_type": "subjective",
                  "description": "*Using data from real Aptfit users, we'll predict how much weight you can lose and how fast if you follow your custom plan.",
                  "response_type_id": 0,
                  "image": "",
                  "options": [
                      {
                          "id": 30,
                          "title": "target",
                          "is_selected": false
                      }
                  ],
                  "meal_types": []
              }
          },
          {
              "id": "12",
              "type": "survey_question",
              "attributes": {
                  "question_id": 12,
                  "question": "Congratulations!",
                  "dependent_on": "both",
                  "response_type": "static",
                  "description": "You just set your first goal.(You can always change this goal later).",
                  "response_type_id": 3,
                  "image": "http://localhost:3000/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbHNKIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--15c8886c33e10109b7bb212441dba4d193cbca67/corina-rainer-noWZPGv6JIU-unsplash.jpg",
                  "options": [],
                  "meal_types": []
              }
          }
      ]
  }
}
// Customizable Area End

