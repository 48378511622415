// Customizable Area Start
import React from "react";

import {
    Button,

} from "@material-ui/core";
import LoadingImage from "./LoadingImage";
import GoalIllustrationController, { Props } from './GoalIllustrationController'

export default class GoalIllustration  extends GoalIllustrationController {
    constructor(props: Props) {
        super(props);
    }
    
    render () {
        const {
            questions,
            setStepFunc,
            visual,
            greeting
          } = this.props;
    const filteredDataa = questions.filter((item: any) => item.attributes.question_id === 1);
    const goalObject = filteredDataa[0]?.attributes.options.find((option: any) => option.is_selected === true)
  console.log(questions,"##")
    const goNext = () => {
        this.getUserToken();
        
    }
   
    return (
        <>
        <LoadingImage minHeight="320px" width="376px" src={visual?.image} alt="" />
           
                        <h1 className="page-title">{greeting}</h1>
                        <p className="static-description">Luckily, your plan is almost ready.As you’ve shared, your main objective is to {goalObject? goalObject.title.toLowerCase() :"get healthier"}. But let’s set one more small goal to keep you motivated</p> 
           
            <Button
                onClick={() => goNext()}
                data-test-id="NextButton"
                className='btn-nextSecondary'
            >Next</Button>
        </>

    );
    }
}



