const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

export const validationMessages = {
    invalid: 'This field must be a valid email.'
}

export function validateEmail(email: string) {
    if (!email) {
        return {
            isValid: false,
            errorMessage: 'Email should not be empty',
        }
    }
    const isValid = emailRegex.test(email.trim());

    return {
        isValid,
        errorMessage: isValid ? '' : 'This field must be a valid email.',
    }
}