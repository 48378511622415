import React, { useEffect, useRef } from 'react'
import {
    Button
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "../assets/css/PageOne.web.css";
import QuestionFormWrapper from './QuestionFormWrapper.web';
// Customizable Area End

interface Props {
    questions: any[];
    step: number;
    attributes: any;
    updateQuestionsAction: (questions: any[]) => void;
    setStepFunc: (step: number) => void;
}

export default function SingleSelectForm({ questions, step, attributes, updateQuestionsAction, setStepFunc }: Props) {
    const { question, description, options } = attributes;
    const chosenRef = useRef(false);
    useEffect(() => {
        chosenRef.current = false;
    }, [step]);

    const onUpdateQuestions = (index: number) => {
        if (chosenRef.current) {
            return;
        }
        chosenRef.current = true;
        let newOptions = [...options];
        newOptions = newOptions.map((option, i) => {
            if (i === index) {
                return { ...option, is_selected: true };
            }

            return { ...option, is_selected: false };
        });
        const newQuestion = { ...questions[step], attributes: { ...attributes, options: [...newOptions] } };
        const copyQuestions = [...questions];

        copyQuestions[step] = newQuestion;
        updateQuestionsAction([...copyQuestions]);
        setTimeout(() => {
            if(attributes.question_id===38) {
                let x=localStorage.getItem("solveques")
                if(x){
                    let pp=JSON.parse(x)
                    pp.push(attributes.question_id)
                    pp=JSON.stringify(pp)
                    localStorage.setItem("solveques",pp)
                }else{
                    let pp:any=[attributes.question_id]
                    pp=JSON.stringify(pp)
                    localStorage.setItem("solveques",pp)
                }
            }
            setStepFunc(1)
        }, 1000);
    };

    return (
        // Customizable Area Start
        <QuestionFormWrapper question={question} description={description}>
            <div className="options">
                {options &&
                    options.map((item: any, index: any) => (
                        <Button className={item.is_selected ? "btn-filled" : "btn-outlined"} data-test-id="singleOption" key={item.id}
                            onClick={() => {
                                onUpdateQuestions(index);
                            }
                            }>{item.title}</Button>
                    ))}
            </div>
        </QuestionFormWrapper>
        // Customizable Area End
    )
}
export const webStyles = {
    singlSelectWrapper: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%"
    },
    singleOptionwrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        marginTop: "10px"
    }
}