import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const configJSON = require("./config");

export type SelectedLink = 'TaC' | 'Privacy';

export interface TermsConditionProps {
    onClose: () => void;
    selectedLink: SelectedLink,
}

interface S {
    text: string;
    isFetching: boolean;
    title: string;
    tc:any;
    pc:any;
}

interface SS {

}

interface TaCItem {
    id: string,
    type: string,
    attributes: {
        id: string,
        title: string,
        description: string,
        contain_type: string,
    }
}

export interface TacSucceessResponse {
    data: TaCItem[],
}

export default class TermsConditionsContainerConroller extends BlockComponent<TermsConditionProps, S, SS> {
    tacRequestMessageId = '';

    private readonly CONTENT = {
        'TaC': {
            contentType: 'terms_and_conditions',
        },
        'Privacy': {
            contentType: 'privacy_notice',
        },
    }

    constructor(props: TermsConditionProps) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.state = {
            text: '',
            isFetching: true,
            title: '',
            tc:'',
            pc:''
        }
    }

    async componentDidMount() {
        this.sendRequest();
    }

    receive(from: string, message: Message): void {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.tacRequestMessageId) {
               this.setState({tc:responseJson?.data[0].attributes,pc:responseJson?.data[1].attributes})
                this.handleGetTaCResponse(responseJson, errorReponse);
                
            }
        }
    }

    handleClose() {
        this.props.onClose();
    }

    private sendRequest() {

        const tacMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage),
        );

        this.tacRequestMessageId = tacMessage.messageId;
        tacMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            '/bx_block_terms_and_conditions/terms_and_conditions',
        );

        tacMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod,
        );
        runEngine.sendMessage(tacMessage.id, tacMessage);
    }

    private handleGetTaCResponse(responseJson: TacSucceessResponse, errorResponse: unknown) {
        
        if (errorResponse) {
            console.error('api-error', errorResponse);
            
            this.setState({
                ...this.state,
                text: 'Unable to get Terms And Conditions',
                isFetching: false,
            });
            return;
        }

        this.handleSuccessTaCResponse(responseJson);
    }

    private handleSuccessTaCResponse(response: TacSucceessResponse) {
        const contentData = this.CONTENT[this.props.selectedLink];
        const data = response.data.find(data => data.attributes.contain_type === contentData.contentType) as TaCItem;
        const { attributes: { title, description } } = data; 
        this.setState({
            ...this.state,
            isFetching: false,
            text: description,
            title,
        })
    }
}