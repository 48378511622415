import React from 'react'
import {
    Button
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

import "../assets/css/PageOne.web.css";
import QuestionFormWrapper from './QuestionFormWrapper.web';
// Customizable Area End

interface Props {
    questions: any[];
    step: number;
    attributes: any;
    updateQuestionsAction: (questions: any[]) => void;
    setStepFunc: (step: number) => void;
}

export default function BodyType({ questions, step, attributes, updateQuestionsAction, setStepFunc }: Props) {
    const { question, description, options } = attributes;

    const onUpdateQuestions = (index: number) => {
        let newOptions = [...options];
        newOptions = newOptions.map((option, i) => {
            if (option.id === index) {
                return { ...option, is_selected: true };
            }

            return { ...option, is_selected: false };
        });
    
        const newQuestion = { ...questions[step], attributes: { ...attributes, options: [...newOptions] } };
        const copyQuestions = [...questions];
        copyQuestions[step] = newQuestion;
        updateQuestionsAction([...copyQuestions]);
      

    }
    const filteredData = questions.filter(item => item.attributes.question_id === 3 );
        const gender = filteredData[0]?.attributes.options.find((option: any) => option.is_selected === true);
    
    let filterdata; 

    if (gender?.id === 12) {
        filterdata = options.filter((person:any) => person.depend_opt_id );
        
    } else {
        filterdata = options.filter((person:any) => person.depend_opt_id === gender?.id);
        
    }
   
const nextStep=()=>{
    const filteredData = questions.filter(item => item.attributes.question_id ===14 );
    const selectedGender = filteredData[0]?.attributes.options.find((option: any) => option.is_selected === true);
    
    if ( selectedGender?.body_type === "male") {
        setStepFunc(1)
    } else {
        setStepFunc(2)
    }
}
    return (
        // Customizable Area Start
        <QuestionFormWrapper question={question} description={description}>
                <div className="image-options">
                {filterdata &&
                    filterdata.map((item: any, index: any) => (
            <Button  className={item.is_selected ? "img-btn active" : "img-btn"} data-test-id="singleOption" onClick={() => {onUpdateQuestions(item.id);}}>
                <img src={item.image} alt="Triangle" height={158}/>
                <p>{item.title}</p>
            </Button>
                    ))}
        </div>
        
             <Button
             
             onClick={() => nextStep()}
             data-test-id="NextButton"
             className='btn-nextSecondary'
             disabled={!options.find((option: any) => option.is_selected)}
         >Next</Button>
        </QuestionFormWrapper>
        
        // Customizable Area End
    )
}
export const webStyles = {
    singlSelectWrapper: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        width: "100%"
    },
    singleOptionwrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        marginTop: "10px"
    }
}