import React from "react";
import HeightForm from "./HeightForm.web";
import WeightForm from "./WeightForm.web";
import SubjectiveFormController, { Props } from './SubjectiveFormController.web'

export default class SubjectiveForm extends SubjectiveFormController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { title } = this.props.attributes.options[0];

        switch (title) {
            case 'height':
                return <HeightForm {...this.props} />
            case 'weight':
                return <WeightForm isTarget={false} {...this.props} />
            case 'target':
                return <WeightForm isTarget={true} {...this.props} />
            default:
                return <p>invalid title</p>
        }
    }

};
