import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  updateQuestionsAction: (questions: any[]) => void;
  setInitialQuestionsAction: (questions: any[]) => void;
  updateStepAction: (step: number) => void;
  reduxStep: number
  updateSkipppedQuestionIdsAction: (questionsIds: any[]) => void;
  questions: any,
  showBackButton: boolean,
  skippedQuestionsIds: any
  // Customizable Area End
}

interface S {

  // Customizable Area Start
  step: number;
  questions: any;
  target_month: any;
  target_weight: string;
  weight_loss_data: any,
  weight_loss_month: any,
  weight_unit: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MultiFormsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQuestionApiCallId: string = ""
  getGraphDetailApiCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      // Customizable Area Start
      weight_unit: "Kg",
      questions: [],
      step: 0,
      target_month: "",
      target_weight: "",
      weight_loss_data: [],
      weight_loss_month: []

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getQuestionApiCallId) {
        this.getQuestionResponseHandler(responseJson, errorReponse);
      }
      if (apiRequestCallId === this.getGraphDetailApiCallId) {
        this.getGraphDetailResponseHandler(responseJson, errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getQuestions()
  }
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): Promise<void> {
    if (prevProps.questions !== this.props.questions || prevProps.reduxStep !== this.props.reduxStep) {
      const { attributes: { response_type } } = this.props.questions[this.props.reduxStep];
      if (response_type === "weight_graph") {
        this.getGraphDetails()
      }
    }
  }
  getResponseTypeFromData = (questions: any, step: number) => {
    if (questions.length === 0) {
      return null;
    }
    const { attributes: { response_type } } = questions[step];

    return response_type;
  }
  getQuestions = () => {

    const getQuestionApiId = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuestionApiCallId = getQuestionApiId.messageId;
    getQuestionApiId.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_surveys2/survey_questions"
    );
    getQuestionApiId.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getQuestionApiId.id, getQuestionApiId);
    return true;
  }
  getGraphDetails = () => {
    const current_weight = this.props.questions.find((ques: { attributes: { question_id: number; }; }) =>
      ques.attributes.question_id === 10
    )
    const targeted_weight = this.props.questions.find((ques: { attributes: { question_id: number; }; }) =>
      ques.attributes.question_id === 11
    )
    this.setState({ weight_unit: targeted_weight.attributes.options[0]?.weight_unit })
    const header = {
      "Content-Type": "application/json",
    }
    const apiMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getGraphDetailApiCallId = apiMessage.messageId;
    apiMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getGraphDetailsApiEndpoint}?current_weight=${current_weight.attributes.options[0]?.answer}&targeted_weight=${targeted_weight.attributes.options[0]?.answer}&unit=${targeted_weight.attributes.options[0]?.weight_unit}`
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    apiMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(apiMessage.id, apiMessage);
    return true;
  }

  setStepFunc = (value: number) => {
    this.props.updateStepAction(this.props.reduxStep + value)
    this.setState({ step: this.state.step + value })
  }

  goBack = () => {
    let temp = this.props.questions[this.props.reduxStep].attributes.question_id === 25;
    const filteredObj = this.props.questions.find((item: any) => item.attributes.question_id === 3);
    const prevQuestionId = this.props.questions[this.props.reduxStep - 1]?.attributes.question_id
    const isPresent = this.props.skippedQuestionsIds.find((item: number) => item == prevQuestionId)
    const filteredData = this.props.questions.filter((item:any) => item.attributes.question_id === 3);
    const selectedGender = filteredData[0]?.attributes.options.find((option: any) => option.is_selected === true);

    let x:any = localStorage?.getItem("solveques");
    
     if (x &&  x !== "[]") {

           let pp = JSON.parse(x);
           let lastqus = pp[pp.length - 1];
           let questionIndex = this.props.questions.findIndex((d: any) => d.attributes.question_id == lastqus);
           pp.pop();
           pp = JSON.stringify(pp);
           localStorage.setItem("solveques", pp);
           this.props.updateStepAction(questionIndex);
             
       }
       else if (isPresent !== undefined) {
      const newArry = this.props.skippedQuestionsIds.filter((item: number) => item != prevQuestionId)
      this.props.updateSkipppedQuestionIdsAction(newArry)
      this.props.updateStepAction(this.props.reduxStep - 2)

    }  
  
    else if (!temp) {
      if (prevQuestionId===5 && selectedGender?.id !==11) {
      this.props.updateStepAction(this.props.reduxStep - 2)
      } else {
        this.props.updateStepAction(this.props.reduxStep - 1)
      }
    } else if (temp) {
      if (filteredObj) {
        this.props.updateStepAction(this.props.reduxStep - 1)

      } else {
        this.props.updateStepAction(this.props.reduxStep - 2)
      }
    }
  }
  getQuestionResponseHandler = (responseJson: any, errorReponse: any) => {
    if (errorReponse) {
      console.log(errorReponse, "<<<<<<<<<Error")
    }
    if (responseJson && responseJson.questions) {
      this.setState({ questions: responseJson.questions.data })
      this.props.setInitialQuestionsAction(responseJson.questions.data);
    } else {
      console.log(responseJson, "<<<<<<<<<Error")
    }
  }
  getGraphDetailResponseHandler = (responseJson: any, errorReponse: any) => {
    if (errorReponse) {
      console.log(errorReponse, "<<<<<<<<<Error")
    }
    if (responseJson) {
      this.setState({
        target_month: responseJson.target_month,
        target_weight: responseJson.target_weight,
        weight_loss_data: responseJson.weight_loss_data,
        weight_loss_month: responseJson.weight_loss_month
      })
    } else {
      console.log(responseJson, "<<<<<<<<<Error")
    }
  }
  // Customizable Area End
}
