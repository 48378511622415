import React from "react";
import HeightFormController, { HeightFormProps } from "./HeightFormCotroller";
import { GenericInput } from "./GenericInput.web";
import FeetInput from "./FeetInput.web";
import SubjectiveView from "./SubjectiveView.web";

export default class HeightForm extends HeightFormController {
  constructor(props: HeightFormProps) {
    super(props);
    this.changeHeightUnit = this.changeHeightUnit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.getUnits = this.getUnits.bind(this);
  }

  render() {
    const [left, right] = this.getUnits();
    const { attributes } = this.props;
    const {
      isValid,
      currentFt,
      currentCm,
      currentIn,
      unit,
      errorMessage,
      isFetching,
      validMessage,
    } = this.state;
    return (
      <SubjectiveView
        left={left}
        right={right}
        attributes={attributes}
        errorMessage={errorMessage}
        isValid={isValid}
        isFetching={isFetching}
        validMessage={validMessage}
        handleSubmit={this.handleSubmit}
      >
        {unit === "Cm" ? (
          <GenericInput
            unit={unit}
            data-testid={'input-cm'}
            value={currentCm}
            isValid={!errorMessage}
            autofocus
            handleChange={(value) => this.handleInput({ cm: +value })}
            maxLength={3}
          />
        ) : (
          <FeetInput
            currentFt={currentFt}
            currentIn={currentIn}
            errorMessage={errorMessage}
            handleFeetInput={(value) => this.handleInput({ feet: +value })}
            handleInchesInput={(value) => this.handleInput({ inches: +value })}
          />
        )}
      </SubjectiveView>
    );
  }
}
