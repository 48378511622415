import React, { useEffect, useRef, useState } from 'react';
import { Box } from "@material-ui/core";

interface GenericInputProps {
    value: number;
    handleChange: (value: string) => void;
    unit: string;
    isValid: boolean;
    inputStyles?: object;
    maxLength?: number;
    step?: number;
    autofocus?: boolean;
    handleFocus?: () => void;
}

export function GenericInput(props: GenericInputProps) {
    const roundValue = () => Math.round(props.value).toString();
    const [val, setVal] = useState(roundValue);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setVal(roundValue());
    }, [props.unit, props.step])

    const handleFocus = () => {
        if (props.value === 0) {
            setVal('');
        }
        if (props.handleFocus) {
            props.handleFocus();
        }
    };

    useEffect(() => {
        if (inputRef.current && props.autofocus) {
            inputRef.current.focus();
            handleFocus();
        }
    }, [props.unit, props.step, props.autofocus]);

    const shouldTriggerInput = (targetValue: string) =>
        !props.maxLength
        || (!targetValue.startsWith('-') && targetValue.length <= props.maxLength)
        || (targetValue.startsWith('-') && targetValue.length > 1 && targetValue.length <= props.maxLength + 1)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const targetValue = e.target.value;
        if (shouldTriggerInput(targetValue)) {
            setVal(e.target.value);
            props.handleChange(e.target.value);
        } else if (targetValue === '-') {
            setVal(e.target.value);
        }
    };

    const handleBlur = () => {
        if (val === '') {
            setVal('0');
        }
    };
    return (
        <Box sx={webStyles.inputWrapper}>
            <input
                ref={inputRef}
                autoFocus={props.autofocus}
                className='number-input'
                data-test-id="heightInputCm"
                onKeyPress={(e) => {
                    const parsedNum = Number(e.key);
                    if (Number.isNaN(parsedNum) && e.key !== '-') {
                        e.preventDefault();
                    }
                }}
                style={{
                    ...(props.isValid ? webStyles.inputFieldValid : webStyles.inputFieldInvalid),
                    ...(props.inputStyles && { ...props.inputStyles })
                }}
                value={val}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onInput={handleChange}
            />
            <p data-testid={'unit-title'} style={webStyles.unit}>{props.unit}</p>
        </Box>
    )
}

const webStyles = {
    unit: {
        margin: "5px",
        color: '#8F9BB3'
    },
    inputWrapper: {
        display: "flex",
        justifyContent: 'center',
        alignItems: "center",
        flexDirection: "row"
    },
    inputFieldValid: {
        color: "black",
    },
    inputFieldInvalid: {
        color: "red",
    },
}