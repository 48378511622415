import { CircularProgress } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import Loader from "./Loader";

type Props = Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'width' | 'minHeight'> & {
    width: string;
    minHeight: string;
};

const LoadingImage: FC<Props> = ({ width, minHeight, src, ...props }) => {
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
    }, [src]);

    return (
        <div
            style={{
                margin: 0,
                padding: 0,
                minHeight,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Loader isVisible={isLoading}/>
            <img
                onLoad={() => {
                    setLoading(false);
                }}
                src={src}
                style={{
                    ...props,
                    display: isLoading ? 'none' : 'block',
                    width,
                    minHeight,
                }} />
        </div>
    )
}

export default LoadingImage;
