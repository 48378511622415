import React, { ReactNode } from "react";
import { Box, Button } from "@material-ui/core";
import ContactUsHint from "../../cfneededforunitconversion5/src/ContactUsHint";
import QuestionFormWrapper from "../../surveys2/src/QuestionFormWrapper.web";
import { Attributes } from "../../surveys2/src/types";

export interface Props {
  attributes: Attributes;
  errorMessage?: string | null;
  isValid: boolean;
  isFetching: boolean;
  validMessage: string;
  left: any;
  right: any;
  handleSubmit: () => void;
  children: ReactNode;
  errorDescription?: string;
}

export default function SubjectiveView(props: Props) {
  const { attributes } = props;
  const { question, description } = attributes;
  const {
    errorMessage,
    isValid,
    isFetching,
    validMessage,
    left,
    right,
    handleSubmit,
    errorDescription
  } = props;
  const isDisabledBtn = (btn: any) => btn.isDisabled || !!errorMessage || isFetching;

  return (
    <QuestionFormWrapper question={question}>
      <Box style={{ width: "100%", margin: "20px 0" }}>
        <Box sx={webStyles.subjectiveWrapper}>
          <Box sx={webStyles.subjectiveWrapper}>
            <Box sx={webStyles.checkboxWrapper}>
              {props.children}
              <Box sx={{ marginBottom: '24px' }}>
                <button
                  style={{
                    ...webStyles.checkboxFirstButton,
                    ...(left.isSelected
                      ? webStyles.selected
                      : webStyles.notSelected),
                    ...(isDisabledBtn(left) ? { cursor: 'auto' } : { cursor: 'pointer' })
                  }}
                  type="button"
                  data-testid={"button1"}
                  onClick={left.handleClick}
                  disabled={isDisabledBtn(left)}
                >
                  {left.title}
                </button>
                <button
                  disabled={isDisabledBtn(right)}
                  style={{
                    ...webStyles.checkboxSecondButton,
                    ...(right.isSelected
                      ? webStyles.selected
                      : webStyles.notSelected),
                    ...(isDisabledBtn(right) ? { cursor: 'auto' } : { cursor: 'pointer' })
                  }}
                  type="button"
                  data-testid={"button2"}
                  onClick={right.handleClick}
                >
                  {right.title}
                </button>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
              >
                {errorMessage && (
                  <>
                    <p
                      data-testid={"text-invalid"}
                      className="invalid-message"
                    >
                      *{errorMessage}
                    </p>
                    <ContactUsHint />
                  </>
                )}
                {validMessage && (
                  <p data-testid={"text-valid"} className="valid-message">
                    {validMessage}
                  </p>
                )}
                {errorDescription && (
                  <p data-testid={"text-invalid-hint"} className='subjective-description' style={{ padding: 0, marginBottom: 0 }}>
                    {errorDescription}
                  </p>
                )}
              </Box>
            </Box>
          </Box>
          {description && (
            <Box
              style={{
                width: "80%",
                marginBottom: "0",
                ...((!!errorDescription || !!errorMessage) && { order: 10 }),
              }}
            >
              <p
                className="subjective-description"
                data-testid={"text-desc"}
                style={{ margin: 0, padding: 0 }}
              >
                {description}
              </p>
            </Box>
          )}
          <Box sx={webStyles.subjectiveNextButtonWraaper}>
            <Button
              data-testid={"next-btn"}
              disabled={!isValid || isFetching}
              onClick={handleSubmit}
              data-test-id="NextButton"
              className="btn-nextSecondary"
            >
              Next
            </Button>
          </Box>
        </Box>
      </Box>
    </QuestionFormWrapper>
  );
}

export const webStyles = {
  subjectiveWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  ftInput: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subjectiveNextButtonWraaper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginBottom: "32px",
  },
  subjectiveButton: {
    fontSize: 16,
    padding: "6px 12px",
    borderRadius: "10px",
    lineHeight: 2,
    width: "100%",
    backgroundColor: "#0063cc",
    height: "56px",
  },
  checkboxWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
  },
  checkboxFirstButton: {
    fontFamily: "Inter",
    fontWeight: 400,
    width: "100px",
    height: "60px",
    border: "1px solid",
    borderColor: "grey",
    borderTopLeftRadius: "50px",
    borderBottomLeftRadius: "50px",
    backgroundColor: "#fff",
    color: "grey",
  },
  selected: {
    color: "#5A55A4",
    borderColor: "#5A55A4",
  },
  notSelected: {
    color: "#8F9BB3",
    borderColor: "#EDF1F7",
  },
  checkboxSecondButton: {
    fontFamily: "Inter",
    fontWeight: 400,
    width: "100px",
    height: "60px",
    border: "1px solid",
    borderColor: "grey",
    borderBottomRightRadius: "50px",
    borderTopRightRadius: "50px",
    backgroundColor: "#fff",
    color: "grey",
  },
};
