import React, { FC } from "react";

const GreetScreenPageDescription: FC<{
    greetingOrTitle?: string;
    description?: string | null;
    isDefaultTitle: boolean;
}> = ({ greetingOrTitle, description, isDefaultTitle }) => {    
    if (greetingOrTitle) {
    console.log('greetingOrTitle', greetingOrTitle);

        return (
            <>
                <h1 className={"page-title"}>{greetingOrTitle}</h1>
                <p className="static-description">{description}</p>
            </>
        )
    }

    if (isDefaultTitle || !description) {
        return null;
    }

    const [upperLine, ...rest] = description.split('.');
    const lowerLine = rest.join('.');
    return (
        <>
            <p style={{ marginBottom: 0 }} className="static-description">{upperLine}.</p>
            <p className="static-description">{lowerLine}</p>
        </>
    )
}

export default GreetScreenPageDescription;