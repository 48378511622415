import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "../assets/Demographic.css";
import { imgone, imgtwo, imgthree,imgdot,imgtick,imgdotb,imgdotg } from "./assets";
import {
  CircularProgressbar,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// Customizable Area End

import DynamiccontentController, {
  Props,
} from "./DynamiccontentController.web";

export default class Dynamiccontent extends DynamiccontentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  onUpdateQuestions = (index: number) => {
    const {
      questions,
      setStepFunc,
      attributes,
      updateQuestionsAction,
      step,
    } = this.props;
    const { options } = attributes;
    let newOptions = [...options];

    newOptions = newOptions.map((option, i) => {
      if (i === index) {
        return { ...option, is_selected: true };
      }

      return { ...option, is_selected: false };
    });
    const newQuestion = {
      ...questions[step],
      attributes: { ...attributes, options: [...newOptions] },
    };
    const copyQuestions = [...questions];

    copyQuestions[step] = newQuestion;
    updateQuestionsAction([...copyQuestions]);

    this.closeModal();

    if (attributes.question_id === 49) {
      setStepFunc(0);
    } else {
      setTimeout(() => {
        setStepFunc(1);
      }, 500);
      
    }
  };

  // Customizable Area End

  render() {
    const {
      attributes,
    
    } = this.props;
    const { question, options } = attributes;

    return (
      <>
        <h2>{this.state.displayText}</h2>
        <div className="progress-container">
          <div className="progressData">
            <span>Analysing body parameters</span>
            <span>{this.state.progress1}%</span>
          </div>
          <div className="progress-bars bgone">
            <div
              className="progress-dashed"
              style={{ width: `calc(${this.state.progress1}% + 2%)` }}
            />
            <div className="img-wrapper brone" style={{ left: `${this.state.progress1}%` }}>
            <img
              src={imgone}
              alt="Icon"
              width={18}
              height={18}
              
            />
            </div>
            
          </div>
        </div>
        <div className="progress-container">
          <div className="progressData">
            <span>Habits and Behaviour</span>
            <span>{this.state.progress2}%</span>
          </div>
          <div className="progress-bars bgtwo">
            <div
              className="progress-dashed colorr"
              style={{ width: `calc(${this.state.progress2}% + 2%)` }}
            />
           <div className="img-wrapper brtwo" style={{ left: `${this.state.progress2}%` }}>
            <img
              src={imgtwo}
              alt="Icon"
              width={18}
              height={18}
              
            />
            </div>
          </div>
        </div>
        <div className="progress-container">
          <div className="progressData">
            <span>Nutrition and Psychology</span>
            <span>{this.state.progress3}%</span>
          </div>
          <div className="progress-bars bgthree">
            <div
              className="progress-dashed colorg"
              style={{ width: `calc(${this.state.progress3}% + 2%)` }}
            />
            <div className="img-wrapper brthree" style={{ left: `${this.state.progress3}%` }}>
            <img
              src={imgthree}
              alt="Icon"
              width={18}
              height={18}
              
            />
            </div>
          </div>
        </div>
        <div className="image-container-icon">
  <img src={this.state.progress1 >=100 ? imgtick : imgdotb} alt="Image 1"/>
  <img src={this.state.progress2 >=100 ? imgtick : imgdot} alt="Image 2"/>
  <img src={this.state.progress3 >=100 ? imgtick : imgdotg} alt="Image 3"/>
</div>

        <p className="demograpgic_des">
          Sit tight! We're building your perfect plan based on millions of data
          points from successful Aptfit users
        </p>
        <Dialog
          open={this.state.isModalOpen}
          // onClose={this.closeModal}
          className="custom-dialog"
          PaperProps={{
            style: {
              width: "400px",
              height: "258px",
              borderRadius: "8px",
              background: "var(--white, #FFF)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        >
          <DialogTitle className="textPopup">{question}</DialogTitle>

          <DialogActions>
            {options &&
              options.map((item: any, index: any) => (
                <Button
                className={item.is_selected ? "btn-dark" : "btn-light"}
                  data-test-id="singleOption"
                  key={item.id}
                  onClick={() => {
                    this.onUpdateQuestions(index);
                  }}
                >
                  {item.title}
                </Button>
              ))}
          </DialogActions>
        </Dialog>
        <Dialog
      open={this.state.seondModal}
      PaperProps={{
        style: {
          width: "400px",
              height: "258px",
              borderRadius: "8px",
              background: "var(--white, #FFF)",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
        },
      }}
    >
      <DialogTitle className="textPopup">Processing meal plan</DialogTitle>
      <div style={{ textAlign: "center", padding: "1px" }}>
        <p className="subtxt">This may take a while</p>
       
        <div className="circularprog">
          <CircularProgressbar
            value={this.state.progress4}
            text={`${this.state.progress4}%`}
            styles={buildStyles({
              pathTransitionDuration: 0.15,
              textColor: "#000",
              pathColor: "#5A55A4",
             
            })}
          />
          </div>
       
   
      </div>
    </Dialog>
      </>
    );
  }
}

// Customizable Area Start

// Customizable Area End
