import React, { useState,useEffect } from 'react'
import { Button,withStyles ,TextField} from "@material-ui/core";

interface Props {
    questions: any[];
    step: number;
    attributes: any;
    setStepFunc: (step: number) => void;
    updateQuestionsAction: (questions: any[]) => void;
}

export default function MultiSelectFrom({ questions, step, attributes, setStepFunc, updateQuestionsAction }: Props) {
    const [isOther, setIsOther] = useState(false)
    const [otherType, setOtherType] = useState("")
    const [isfeildError, setError] = useState(false)
    const [otherFeildErrorMessage,setErrorMessage]=useState("")
    const { question, description, options } = attributes;
    const index= options.findIndex((option:any)=>option.title=="Other")
   
    useEffect(()=>{
        if(index!==-1){
            if(options[index]?.is_selected){
                setIsOther(true)
                setOtherType(options[index]?.answer_data)
            }
        }
    },[])
    const setUpdateQuestions=(index:number,item:any,answer_data?:any)=>{
        let newOptions = [...options];
        let selectedOption = newOptions[index];
        if(answer_data){
            newOptions[index] = { ...selectedOption, is_selected: true ,answer_data:answer_data};
        }else{
            const {answer_data,...rest} = options[index];
            newOptions[index] = { ...rest, is_selected: !rest.is_selected };
        }
        let newQuestion = { ...questions[step], attributes: { ...attributes, options: [...newOptions] } };
        if(item.title=="None"){
            setIsOther(false);
            setOtherType("")
        }
        let resetquestion = newQuestion.attributes.options.map((d: any) => {
            if (d.title == "None") {
                return (item.title !== "None") ? { ...d, is_selected: false } : d
            }else if(d.title == "Other" && item.title=="None"){
               const {answer_data,...rest} = d
               return {...rest, is_selected:false}
            }
             else {
                return (item.title === "None") ? { ...d, is_selected: false } : d
            }
        })
        newQuestion.attributes.options = resetquestion;
        const copyQuestions = [...questions];
        copyQuestions[step] = newQuestion;
        updateQuestionsAction([...copyQuestions]);
    }
    const onUpdateQuestions = (index: number, item: any) => {
        if (item.title == "Other") {
            setIsOther(!isOther)
            setOtherType("")
        }
        setUpdateQuestions(index,item)
        

    }
    const setOtherFeild = (event: any, item: any) => {
        setOtherType(event.target.value)
        if(event.target.value.length <4){
                setError(true)
                setErrorMessage("Please enter at least 4 characters to proceed")
        }
        else{
            setError(false)
            setErrorMessage("")
        }
    }
  
    const nextStep = () => {
       if(isOther && otherType.length<4 ){
        setError(true)
        setErrorMessage("Please enter at least 4 characters to proceed")
        return 
       }
       else if(isOther && otherType.length>=4){
       const index= options.findIndex((option:any)=>option.title=="Other")
       setUpdateQuestions(index,options[index],otherType)
       }
        const filteredData = questions.filter(item => item.attributes.question_id === 3);
        const selectedGender = filteredData[0]?.attributes.options.find((option: any) => option.is_selected === true);
        if (attributes.question_id === 4) {
            if (selectedGender && selectedGender?.id !== 11) {
                setStepFunc(2)
            } else {
                setStepFunc(1)
            }
        } else {
            setStepFunc(1)
        }
    }

    return (
        <>
            <h1 className="page-title">{question}</h1>
            {description && <p className='page-description'>{description}</p>}
            <div className="options">
                {options &&
                    options.map((item: any, index: any) => (
                        <>
                            <Button className={item.is_selected ? "btn-multiSelected" : "btn-outlined"} data-test-id="option"
                                onClick={() => onUpdateQuestions(index, item)}
                            >{item.title}</Button>
                            {item.title == "Other" && isOther && <> <StylesInput autoFocus id="filled-basic" variant="filled" 
                             placeholder='Please type your input' value={otherType} data-test-id="otherField" inputProps={{ maxLength: 100 }}
                             onChange={(event: any) => setOtherFeild(event, item)}  maxRows={2}/>
                             {isfeildError && <p style={{color:"red",marginTop:"-5px"}}>{otherFeildErrorMessage}</p>}
                             </>
                            }
                        </>
                    ))}
            </div>
            <Button
                onClick={() => nextStep()}
                data-test-id="NextButton"
                className='btn-nextSecondary'
                disabled={!options.find((option: any) => option.is_selected)}
            >Next</Button>
        </>
    )
}
const StylesInput = withStyles({
    root: {
      width: "100%",
      border: "none",
      minHeight: "56px",
      marginBottom: "20px",
      "&$focused": {
        color: "red",
      },
      "& .MuiFilledInput-underline": {
        "&::after": {
            borderColor: "lightgrey",
        }
      }
    }
  })(TextField);
export const webStyles = {
   
}