import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
   greeting:string
    step: number;
    questions: any[];
    setStepFunc: (step: number) => void;
    visual:any;
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GoalIllustrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTokenApiCallId:any;
  getUserApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

     // Customizable Area Start
     this.subScribedMessages = [
        getName(MessageEnum.AccoutLoginSuccess),
        // Customizable Area Start
        getName(MessageEnum.RestAPIResponceMessage),
        // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      if (apiRequestCallId === this.getTokenApiCallId) {
   this.getUserData(responseJson.meta.token)
localStorage.setItem('token',responseJson.meta.token)
      }
      if (apiRequestCallId === this.getUserApiCallId) {
        console.log(responseJson,"@@")
        this.props.setStepFunc(1);
           }
     
    }
    // Customizable Area End
  }
  // Customizable Area Start
  getUserToken=()=>{
    
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const umail=localStorage.getItem('userMail')
    const raw = JSON.stringify({
      "data": {
        "attributes": {
          "email": umail
        },
        "type": "email_account"
      }
    });
    const httpBody = raw

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTokenApiCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/accounts`
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        myHeaders
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        httpBody
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

}

getUserData=(token:any)=>{
  const myHeaders = new Headers();
  myHeaders.append("token", `${token}`);
  myHeaders.append("Content-Type", "application/json");
 
  const httpBody = this.props.questions;

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getUserApiCallId = requestMessage.messageId;

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_surveys2/survey_answers`
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      myHeaders
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;

}
  // Customizable Area End
} 
