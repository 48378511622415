import React from 'react'
import {Button } from "@material-ui/core"
import LoadingImage from './LoadingImage'
const DataVisualization = ({setStepFunc,questions,attributes}:any) => {
    const {image,random_num,question} = attributes
    const goNext=()=>{
        setStepFunc(1)
    }
   const goalQuestion= questions.find((que: { attributes: { question_id: number } })=>que.attributes.question_id===1)
   let selectedOption;
   if(!goalQuestion){
      goNext()
   }else{
     selectedOption= goalQuestion.attributes.options.find((option: { is_selected: boolean })=>option.is_selected)
   }
  return (
    <>
    <p className="page-title">{question}</p>
    <p className="static-description" style={{width:"100%"}}>We’ve helped {random_num} individuals on Mediterranean diet successfully {selectedOption?.title?.toLowerCase()}.</p>
    <LoadingImage src={image} width='90%' minHeight='171px'/>
    <div style={{margin:"20px",height:"10px",width:"100%"}}></div>
    <Button
        onClick={() => goNext()}
        data-test-id="NextButton"
        className='btn-nextSecondary'
    >Next</Button>
</>
  )
}

export default DataVisualization