import { SET_EMAIL, SET_QUESTIONS, SET_SHOW_BACK_BUTTON, SET_INITIAL_QUESTIONS ,SET_STEP,SET_SKIPPED_QUESTION_IDS} from "../Reducers/QuestionReducer";

export function updateQuestionsAction(data: []) {
   return {
      type: SET_QUESTIONS,
      payload: data,
   }
}

export function setInitialQuestionsAction(data: []) {
   return {
      type: SET_INITIAL_QUESTIONS,
      payload: data,
   }
}


export function updateEmailAction(email: string) {
   return {
      type: SET_EMAIL,
      payload: email,
   }
}
export function updateSkipppedQuestionIdsAction(questionIds: number[]) {
   return {
      type: SET_SKIPPED_QUESTION_IDS,
      payload:questionIds ,
   }
}

export function updateStepAction(step: number) {
   return {
      type: SET_STEP,
      payload: step,
   }
}

export function updateShowBackButton(showBackButton: boolean) {
   return {
      type: SET_SHOW_BACK_BUTTON,
      payload: showBackButton,
   }
}