// Customizable Area Start
import React from "react";

import {
    Button,

} from "@material-ui/core";
import { Attributes, Question } from "./types";
import PageDescription from './GreetScreenPageDescription.web';
import { getOrDefault } from "../../cfneededforunitconversion5/src/utils";
import LoadingImage from "./LoadingImage";



const getAttributes = (attributes: Attributes, questions: Question[], step: number) => {

    if (!attributes?.options.length || attributes.options[0]?.title === "dynamic") {
        return {
            title: null,
            image: attributes.image,
            description: attributes.description,
        }
    }
    const prevAttributes = questions[step - 1].attributes;

    const selectedPreviousOption = prevAttributes.options.filter(op => op.is_selected);
    const optionsToShow = attributes.options.filter(option => selectedPreviousOption.find(selected => selected.id === option.depend_opt_id));

    if (!selectedPreviousOption.length || !optionsToShow.length) {
        return null;
    }

    if (optionsToShow.length === 1) {
        const option = optionsToShow[0];
        return {
            title: option.title,
            image: option.image,
            description: option.description,
            dependency: selectedPreviousOption[0],
            bodyType: option.body_type,
        }
    }

    const filteredDatas = questions.filter((item: any) => item.attributes.question_id === 14);
    const gender = filteredDatas[0]?.attributes.options.find((option: any) => option.is_selected === true);
    const genderDepOption = optionsToShow.find(option => option.body_type === gender?.body_type);
    if (!genderDepOption) {
        return null;
    }
    return {
        title: genderDepOption.title,
        image: genderDepOption.image,
        description: genderDepOption.description,
        dependency: selectedPreviousOption[0],
        bodyType: genderDepOption.body_type,
    }
}


export const GreetStaticScreen = ({
    greeting,
    setStepFunc,
    questions,
    step,
    visual,updateSkipppedQuestionIdsAction,
    skippedQuestions
}: any) => {
    if (!questions[0]) {
        return <>Invalid Question</>;
    }

    const currentQuestionId = visual.question_id;

    const goNext = () => {
        const filteredDatas = questions.filter((item: any) => item.attributes.question_id === 14);
        const selectedGender = filteredDatas[0]?.attributes.options.find((option: any) => option.is_selected === true);
        if (selectedGender?.body_type === "male") {
            if (currentQuestionId === 15) {
                setStepFunc(2)

            } else {

                setStepFunc(1)
            }

        } else {
            setStepFunc(1)
        }
    }
    const currentQuestionAttributes = getAttributes(visual, questions, step);

    if (!currentQuestionAttributes) {
        const skippedQuestionsId = questions[step].attributes.question_id
        skippedQuestions.push(skippedQuestionsId)
        updateSkipppedQuestionIdsAction(skippedQuestions)
        setStepFunc(1);
        return null;
    }

    const { image, description, dependency, title } = currentQuestionAttributes;
    const filteredDataa = questions.filter((item: any) => item.attributes.question_id === 1);
    const goalObject = filteredDataa[0]?.attributes.options.find((option: any) => option.is_selected === true)
    const filteredDat = questions.filter((item: any) => item.attributes.question_id === 2);
    const age = filteredDat[0]?.attributes.options.find((option: any) => option.is_selected === true)
    const greetingOrTitle = greeting || title;
    const isDefaultBtn = dependency?.title !== 'No' || currentQuestionId !== 27;
    const isDefaultTitle = currentQuestionId !== 31;
    return (
        <>
            {!greetingOrTitle && isDefaultTitle && <p className="static-descriptions">{description}</p>}

            <LoadingImage minHeight="320px" width="376px" src={image} alt="" />
            {
                visual.options[0]?.title == "dynamic" ?
                    (<p className="static-description">
                        {getOrDefault(visual?.random_num, '')} individuals of age {getOrDefault(age?.title, 'under 70')} successfully {getOrDefault(goalObject?.title, 'got healthier')} with Aptfit by changing their habits and behaviours and following Aptfit diet.</p>)
                    : <PageDescription greetingOrTitle={greetingOrTitle} description={description} isDefaultTitle={isDefaultTitle} />
            }
            <Button
                onClick={() => goNext()}
                data-test-id="NextButton"
                className={isDefaultBtn ? 'btn-nextSecondary' : 'btn-nextBenefit'}
            >{isDefaultBtn ? 'Next' : 'Benefit'}</Button>
        </>
    );

}





